@use '@angular/material' as mat;
@use '@angular/material/prebuilt-themes/indigo-pink.css';
@use '../node_modules/ngx-toastr/toastr.css';
@use 'quill/dist/quill.snow.css';
@use './styles/button.scss' as button;
@use './styles/scrollbar.scss' as scrollbar;

@include mat.elevation-classes();
@include mat.app-background();

@import 'https://fonts.googleapis.com/css?family=Heebo';

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: 'Heebo', sans-serif;
  outline: 0;
  border: 0;
}

ul {
  list-style-position: inside;
}

.mat-slide-toggle.mat-checked:not(.mat-disabled) {
  .mat-slide-toggle-thumb {
    background-color: #009ac6;
  }

  .mat-slide-toggle-bar {
    background-color: #9fa8da;
  }
}

.mat-form-field.mat-focused {
  .mat-form-field-ripple {
    background-color: #009ac6;
  }

  .mat-form-field-label {
    color: #009ac6;
  }
}

.mat-mdc-form-field-appearance-fill .mat-mdc-form-field-flex {
  background: none !important;
}

.mdc-text-field--filled.mdc-text-field--disabled {
  background: none !important;
}

.mdc-text-field--filled.mdc-text-field {
  background: none !important;
}

.dialog__backdrop {
  background-color: rgba(0, 0, 0, 0.5) !important;
}